<template>

  <div>

     <!-- Tambah Data -->
    <!-- <div> -->
      <!-- <b-button class="btn mb-2" @click="TambahData()" variant="outline-primary" v-if="$can('manage','all')">Tambah Agama</b-button> -->
      <!-- <tambah-data
        v-if="showTambahData === true"
        :negaraOptions="negaraOptions.options"
        :provinsiOptions="provinsiOptions.options"
        :kotaOptions="kotaOptions.options"
        @emitLoadKota="onchangeProvinsi"
        @refetchData="refetchData"
        @emitPilihInstansi="pilihInstansi"
        @emitCloseTambahData="closeTambahData"
        @closeTambahData="closeTambahData"
       />
       <edit-data
        v-if="showEditData === true"
        :instansiId="instansiId"
        @emitCloseEditData="closeEditData"
        :negaraOptions="negaraOptions.options"
        :provinsiOptions="provinsiOptions.options"
        :kotaOptions="kotaOptions.options"
        @emitLoadKota="onchangeProvinsi"
        @refetchData="refetchData"
        @emitReloadDataKerjasama="reloadDataKerjasama"
       /> -->
    <!-- </div> -->

    <!-- Filters -->
    <!-- <list-filters
    /> -->

    <b-row>
      <b-col
        cols="12"
        md="2"
        class="mb-md-0 mb-2"
      >
        <analisis
        />
        <lanjutan
        />
      </b-col>
      <b-col
        cols="12"
        md="2"
        class="mb-md-0 mb-2"
      >
        <pendahuluan
        />
        <bap
        />
      </b-col>
      <b-col
        cols="12"
        md="8"
        class="mb-md-0 mb-2"
      >
        <analytics-avg-sessions :data="datas.avgSessions" />
      </b-col>
    </b-row>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row class="mb-5">
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h5>MONITORING PERSIDANGAN</h5>
            </b-col>
          
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              v-if="!is_pilihan"
            >
              <b-dropdown id="dropdown-1" text="Export" variant="primary" class="m-md-2">
                <b-dropdown-item><feather-icon icon="FileIcon" /> PDF</b-dropdown-item>
                <b-dropdown-item><feather-icon icon="FileIcon" /> Excel</b-dropdown-item>
              </b-dropdown>
            </b-col>
        </b-row>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cari no register, perihal"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(perihal)="data">
          <div v-html="data.item.perihal" vertical-align="center">
          </div>
        </template>

        <template #cell(file_perihal)="data">
          <div v-if="data.item.file == null" vertical-align="center">
            Tidak Ada
          </div>
          <div v-else vertical-align="center">
            <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-primary" @click="lihatFile(data.item.file)" title="Lihat File">
                <feather-icon icon="FileIcon" />
              </b-button>
          </div>
        </template>

        <template #cell(status_laporan_modul)="data">
          <b-media vertical-align="center">
              
              <b-badge pill :variant="`light-${data.item.status.class}`">{{ data.item.status.modul }}</b-badge>
          </b-media>
        </template>

        <!-- <template #cell(status_laporan_modul)="data">
          <b-media vertical-align="center">
              <b-badge pill :variant="`light-${data.item.status.class}`">{{ data.item.status_laporan_nama }}</b-badge>
          </b-media>
        </template> -->


         <!-- Column: Actions -->
         <template #cell(actions)="data">
            <div class="d-flex" v-if="is_pilihan">
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-primary"  title="Pilih" @click="pilihInstansi(data.item.id, data.item.no_register)">
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
            </div>
            <div class="d-flex" v-else>
              <!-- <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-primary" v-b-modal="`modal-detail-persidangan-monitoring-${data.item.id}`" title="Detail"> -->
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-primary" v-b-modal="`modal-detail-persidangan-monitoring-${data.item.id}`" title="Detail">
                <feather-icon icon="SearchIcon" />
              </b-button>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="primary" :to="{ name: 'monitoring-persidangan-detail', params: { laporan_id: data.item.id, mode: 'readonly' } }" title="Lihat Data">
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-modal :id="`modal-detail-persidangan-monitoring-${data.item.id}`" centered  title="Detail Monitoring Persidangan" hide-footer>
                <b-row>
                  <b-col
                    xl="12"
                  >
                  <b-form-group
                      label="No Penerimaan"
                      label-for="No Penerimaan"
                    >
                      <b-form-input
                        id="no_penerimaan"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.no_penerimaan"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Tanggal Penerimaan"
                      label-for="tgl_penerimaan"
                    >
                      <b-form-datepicker
                        id="tgl_penerimaan"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.tgl_penerimaan"
                        locale="id"
                      />
                    </b-form-group>

                    <b-form-group
                      label="No Register"
                      label-for="No Register"
                    >
                      <b-form-input
                        id="no_register"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.no_register"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Tanggal Register"
                      label-for="tgl_register"
                    >
                      <b-form-datepicker
                        id="tgl_register"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.tgl_register"
                        locale="id"
                      />
                    </b-form-group>

                    <b-form-group
                      label="No Putusan"
                      label-for="No Putusan"
                    >
                      <b-form-input
                        id="no_putusan"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.no_putusan_custom"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Perihal"
                      label-for="Perihal"
                    >
                      <!-- <b-form-textarea
                        id="perihal"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.perihal"
                      /> -->
                      <div class="form-control" style="height: auto; background-color: #efefef;">
                          <p v-html="data.item.perihal"></p>
                      </div>

                      <!-- <b-form-textarea ><p :v-html="data.item.perihal"></p></b-form-textarea> -->
                    </b-form-group>

                    <b-form-group
                      label="Status Persidangan"
                      label-for="status_persidangan"
                    >
                      <b-form-input
                        id="status_persidangan"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.status.modul"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Pelapor"
                      label-for="pelapor"
                    >
                      <b-form-textarea
                        id="pelapor"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.nama_pelapor_custom"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Terlapor"
                      label-for="terlapor"
                    >
                      <b-form-textarea
                        id="terlapor"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.nama_terlapor_custom"
                      />
                    </b-form-group>

               

                    <b-form-group
                      label="Waktu Proses Terakhir"
                      label-for="Waktu Proses Terakhir"
                    >
                      <b-form-input
                        id="Waktu Proses Terakhir"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.waktu_proses_terakhir_custom"
                      />
                    </b-form-group>

                   
                    <b-form-group
                      label="Created Date"
                      label-for="Created Date"
                    >
                      <b-form-datepicker
                        id="Created Date"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.created_at_custom"
                        locale="id"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Created By"
                      label-for="Created By"
                    >
                    <b-form-input
                      id="Created By"
                      autofocus
                      trim
                      placeholder=""
                      :disabled="true"
                      v-if="data.item.user_created_name !== 'Tidak Tersedia'"
                      v-model="data.item.user_created_name"
                    />
                    <b-form-input
                      id="Created By"
                      autofocus
                      trim
                      placeholder=""
                      :disabled="true"
                      v-else-if="data.item.user_created_name === 'Tidak Ada Nama'"
                      v-model="data.item.user_created_name"
                    />
                    <b-form-input
                      id="Created By"
                      autofocus
                      trim
                      placeholder=""
                      :disabled="true"
                      v-else
                      v-model="data.item.pelapor_created_name"
                    />
                    </b-form-group>

                    <b-form-group
                      label="Last Updated Date"
                      label-for="Last Updated Date"
                    >
                      <b-form-datepicker
                        id="Last Updated Date"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-model="data.item.updated_at_custom"
                        locale="id"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Last Updated By"
                      label-for="Last Updated By"
                    >
                      <b-form-input
                        id="Last Updated By"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-if="data.item.user_updated_name !== 'Tidak Tersedia'"
                        v-model="data.item.user_updated_name"
                      />
                      <b-form-input
                        id="Last Updated By"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-else-if="data.item.user_updated_name === 'Tidak Ada Nama'"
                        v-model="data.item.user_updated_name"
                      />
                      <b-form-input
                        id="Last Updated By"
                        autofocus
                        trim
                        placeholder=""
                        :disabled="true"
                        v-else
                        v-model="data.item.pelapor_updated_name"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
            </div>
         </template>

        <!-- Column: Actions
        <template #cell(actions)="data">
            <div v-if="$route.params.id">
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-success"  v-if="data.item.terpilih === true"
              >
                Terpilih
              </b-button>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="primary" @click="pilihInstansi(data.item.id)" v-else
              >
                Pilih
              </b-button>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="info" @click="editData(data.item.id)" title="Ubah Data">
                <feather-icon icon="EditIcon" />
              </b-button>
              
            </div>
            <div v-else-if="jadwal_pembahasan_id">
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="danger" @click="undang(data.item.id, jadwal_pembahasan_id)"  v-if="data.item.terpilih === true"
              >
                -
              </b-button>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="success" @click="undang(data.item.id, jadwal_pembahasan_id)" v-else
              >
                +
              </b-button>
            </div>
            <div v-else>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="info" @click="editData(data.item.id)" title="Ubah Data">
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                class="btn-icon btn btn-relief-primary"
                variant="outline-danger"
              @click="deleteData(data.item.id, data.item.nama)" title="Hapus Data" alt="Hapus Data"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span> //-->
              <!-- </b-button> -->
            <!-- </div> -->
          <!-- </b-dropdown> -->
        <!-- </template> -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormGroup, BFormTextarea, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/monitoring-persidangan/storeModule'
import useList from '@/connection-api/monitoring-persidangan/list'
// import TambahData from './TambahData.vue'
// import EditData from './EditData.vue'
import listFilters from './ListFilters.vue'
import analisis from './dashboard/Analisis.vue'
import pendahuluan from './dashboard/Pendahuluan.vue'
import lanjutan from './dashboard/Lanjutan.vue'
import bap from './dashboard/Bap.vue'
import AnalyticsAvgSessions from './dashboard/AnalyticsAvgSessions.vue'
// import {
//   getNegara,
//   getProvinsi,
//   getKota,
//   deleteAgama,
// } from '@/connection-api/master'
// import {
//   pilihUnitUtama,
// } from '@/connection-api/kerjasama'
// import {
//   undangUnitUtama,
// } from '@/connection-api/jadwal-pembahasan'
import {
  downloadPerihal,
} from '@/connection-api/master'


export default {
  // computed: {
  //   updated_at_custom() {
  //     if (this.data.item.nama_pelapor_u !== null) {
  //       return this.data.item.nama_pelapor_u;
  //     } else if (this.data.item.nama_pelapor_p !== null) {
  //       return this.data.item.nama_pelapor_p;
  //     } else {
  //       return "Tidak Tersedia";
  //     }
  //   },
  // },
  components: {
    AnalyticsAvgSessions,
    listFilters,
    analisis,
    pendahuluan,
    lanjutan,
    bap,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    // EditData,
    vSelect,
    BModal,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BFormDatepicker,
  },
  data() {
    return {
      datas: {},
      instansiId: null,
      showTambahData: false,
      showEditData: false,
      negaraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Negara --' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi --' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      },
    }
  },
  props: {
    pihak: {},
    jadwal_pembahasan_id: {},
    is_pilihan: {},
  },
  async mounted() {
      // const respNegara = await getNegara()
      // this.mappingSelectOption(respNegara.data, this.negaraOptions.options)

      // const respProvinsi = await getProvinsi()
      // this.mappingSelectOption(respProvinsi.data, this.provinsiOptions.options)
    
      if(this.$route.params.id)
      {
        this.dataKerjasama = {
          kerjasama_id: this.$route.params.id,
          pihak_ke: this.pihak,
        }
      }
      if(this.jadwal_pembahasan_id)
      {
        this.peserta = {
          jadwal_pembahasan_id: this.jadwal_pembahasan_id,
        }
      }
 
    },
    created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.datas = response.data })
  },
  methods: {
    async lihatFile(filename) {
      this.show = true;
      try {
        const resp = await downloadPerihal(
          filename
        );
        var type_file = filename.substring(filename.length - 4);
        if (type_file === ".pdf") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/pdf" })
          );
        } else {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpeg" })
          );
        }
        this.show = false;
        window.open(this.imageFile);
      } catch (error) {
        this.show = false;
        // this.makeToast("danger", JSON.stringify(error.response.data));
        this.makeToast("danger", JSON.stringify("File tidak ditemukan!"));
      }
    },
    closeEditData(){
      this.showEditData = false
    },
    closeTambahData(){
      this.showTambahData = false
    },
    editData(id) {
      this.showTambahData = false
      this.showEditData = false
      this.instansiId = id
      this.showEditData = true
    },
    async pilihInstansi(id, no_register)
    {
      this.$emit('getDataLaporan', id,no_register)
      this.$root.$emit('bv::hide::modal', 'pilih_laporan_tambah_jadwal_panel')
    },
    async undang(id, jadwalPembahasanId)
    {
      const data = {
        jadwal_pembahasan_id: jadwalPembahasanId,
        unit_utama_id: id,
      }

      const response = await undangUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Agama',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
      }
    },
    reloadDataKerjasama() {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    async deleteData(id, nama) 
    {
      if(window.confirm(`Apakah anda yakin ingin menghapus Agama ${nama} ?`))
      {
        const data = {
          id: id
        }
        const response = await deleteAgama(data)
        if(response.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Agama',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil di hapus',
              },
            })
            this.refetchData()
        }
        else
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Agama',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
        }
      }
    },
    async onchangeProvinsi(provinsiId)
    {
      const data = {
        provinsi_id: provinsiId
      }
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      }
      const respKota = await getKota(data)
      this.mappingSelectOption(respKota.data, this.kotaOptions.options)
    },
    // TambahData()
    // {
    //   if(this.showTambahData === false)
    //   {
    //     this.showTambahData = true;
    //     this.showEditData = false
    //   }
    //   else
    //   {
    //     this.showTambahData = false;
    //     this.showEditData = false
    //   }
    // },
    emitAddSuccess(assetId) {
      this.$router.replace(`/agama/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Agama',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'monitoring-persidangan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      dataKerjasama,
      peserta,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      dataKerjasama,
      peserta,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
