<template>
  <b-card no-body>
    <!-- <b-card-header class="pb-50">
      <h5>
        Dashboard
      </h5>
    </b-card-header> -->
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >
          <div class="d-flex justify-content-center">
            <b-media-aside class="ml-1">
              <b-avatar
                size="48"
                :variant="'light-primary'"
              >
                <feather-icon
                  size="24"
                  :icon="'BriefcaseIcon'"
                />
              </b-avatar>
            </b-media-aside>
          </div>
          <center class="mt-1"><h5>35</h5></center>
          <center><h5>ANALISIS</h5></center>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  methods: {
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
