<template>

  <div>

     <!-- Tambah Data -->
    <div>
      <tambah-data
        v-if="showTambahData === true"
        @refetchData="refetchData"
        @emitPilihInstansi="pilihInstansi"
        @emitCloseTambahData="closeTambahData"
        @closeTambahData="closeTambahData"
       />
       <edit-data
        v-if="showEditData === true"
        :instansiId="instansiId"
        @emitCloseEditData="closeEditData"
        @refetchData="refetchData"
        @emitReloadDataKerjasama="reloadDataKerjasama"
       />
    </div>

    <!-- Filters -->
    <!-- <list-filters
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <div>
                <b-modal id="modal-scrollable" size="lg" scrollable title="List Anggota">
                  <anggota :tim_majelis_id="tim_majelis_id" @emitRefetch="emitRefetch"/>
                </b-modal>
              </div>
              <b-button class="btn" v-b-modal.modal-scrollable variant="outline-primary" v-if="$can('manage','all')">Tambah</b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(nama)="data">
          <b-media vertical-align="center">
              {{ data.item.nama }}
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <div class="d-flex">
              <b-button class="btn mr-1" variant="primary" @click="tandaiKetua(data.item.id)" v-if="data.item.ketua_terpilih === false">Tandai Ketua</b-button>
              <b-button class="btn mr-1" variant="success"  v-if="data.item.ketua_terpilih === true">Ketua</b-button>
              <b-button
                class="btn-icon btn btn-relief-primary"
                variant="outline-danger"
                @click="deleteData(data.item.id, data.item.nama)" title="Hapus Data" alt="Hapus Data"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/tim-user-majelis/storeModule'
import useList from '@/connection-api/tim-user-majelis/list'
import TambahData from './TambahData.vue'
import EditData from './EditData.vue'
import listFilters from './ListFilters.vue'
import Anggota from '@/views/tim-majelis/tim-user/anggota/list/List.vue'
import {
  getNegara,
  getProvinsi,
  getKota,
  deleteTimUserMajelis,
  pilihKetuaTimMajelis,
} from '@/connection-api/master'

export default {
  components: {
    listFilters,
    TambahData,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BLink,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    EditData,
    vSelect,
    Anggota,
  },
  data() {
    return {
      instansiId: null,
      showTambahData: false,
      showEditData: false,
      // negaraOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Negara --' },
      //   ],
      // },
      // provinsiOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Provinsi --' },
      //   ],
      // },
      // kotaOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Kota --' },
      //   ],
      // },
    }
  },
  props: {
    pihak: {},
    jadwal_pembahasan_id: {},
    tim_majelis_id: {},
  },
  async mounted() {
      if(this.tim_id != undefined)
      {
        this.timId = this.tim_id
      }
    },
  methods: {
    emitRefetch()
    {
      this.$emit('emitRefetchTim')
      this.refetchData()
    },
    closeEditData(){
      this.showEditData = false
    },
    closeTambahData(){
      this.showTambahData = false
    },
    editData(id) {
      this.showTambahData = false
      this.showEditData = false
      this.instansiId = id
      this.showEditData = true
    },
    async tandaiKetua(id)
    {
       const data = {
        tim_majelis_id: this.tim_majelis_id,
        id: id,
        is_ketua: 1,
      }
      const response = await pilihKetuaTimMajelis(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tim Majelis',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
            this.reloadDataKerjasama()
      }
    },
    async pilihInstansi(id)
    {
      const data = {
        unit_utama_id: id,
        kerjasama_id: this.$route.params.id,
        pihak_ke: this.pihak,

      }
      const response = await pilihUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tim Majelis',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
            this.reloadDataKerjasama()
      }
    },
    async undang(id, jadwalPembahasanId)
    {
      const data = {
        jadwal_pembahasan_id: jadwalPembahasanId,
        unit_utama_id: id,
      }

      const response = await undangUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tim',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
      }
    },
    reloadDataKerjasama() {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    async deleteData(id, nama) 
    {
      if(window.confirm(`Apakah anda yakin ingin menghapus tim ${nama} ?`))
      {
        const data = {
          id: id
        }
        const response = await deleteTimUserMajelis(data)
        if(response.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tim Majelis',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil di hapus',
              },
            })
            this.refetchData()
        }
        else
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Tim Majelis',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
        }
      }
    },
    async onchangeProvinsi(provinsiId)
    {
      const data = {
        provinsi_id: provinsiId
      }
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      }
      const respKota = await getKota(data)
      this.mappingSelectOption(respKota.data, this.kotaOptions.options)
    },
    TambahData()
    {
      if(this.showTambahData === false)
      {
        this.showTambahData = true;
        this.showEditData = false
      }
      else
      {
        this.showTambahData = false;
        this.showEditData = false
      }
    },
    emitAddSuccess(assetId) {
      this.$router.replace(`/tim-user/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tim Majelis',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = 'tim-user-majelis'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      dataKerjasama,
      peserta,
      timId,
    } = useList(props)

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      dataKerjasama,
      peserta,
      timId,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
