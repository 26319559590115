<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Tambah Data 
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>NIP</label>
           <b-form-input
              id="nip"
              autofocus
              trim
              placeholder="NIP"
              v-model="localData.nip"
            />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
           <label>Nama</label>
           <b-form-input
              id="nama"
              autofocus
              trim
              placeholder="Masukkan Nama Instansi"
              v-model="localData.nama"
            />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
            <div class="mt-2">
              <b-button class="btn btn-primary" variant="info" @click="tambahData">Tambah</b-button>
            </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
    tambahTenagaAhli,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    pihak: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
  },
  methods: {
    async tambahData()
    {
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await tambahTenagaAhli(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tambah Data',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$emit('closeTambahData')
          this.$emit('getTenagaAhliIdAfterCreated', response.data.asset_id)
          this.$emit('closeTambahTenagaAhli')
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tambah Data',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
